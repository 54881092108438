import React from "react";
import Header from "../components/Header";
import ServicioList from "../components/ServicioList";
import Footer from "../components/Footer";
import FooterBar from "../components/FooterBar";

import Hotel from "../assets/hotel.webp";
import Vilafortuny from "../assets/salon-vilafortuny.jpg";
import Toni from "../assets/salon-toni.jpg";
import Oasis from "../assets/oasis.jpg";
import Habitacion from "../assets/habitacion.webp";

import "../css/ForBusiness.css";

const ForBusinessPage = () => {
  return (
    <div className="bg-gray-100 font-barlow text-oxfordBlue">
      <Header />

      <section class="bg-ballBlue/60">
        <div class="flex flex-col md:flex-row">
          <img
            src={Hotel}
            alt="Fachada Hotelet elRetiro"
            class="w-full h-48 md:w-1/2 md:h-80 object-cover object-bottom 2xl:h-96"
          />
          <div class="w-11/12 h-auto m-auto text-white md:w-1/2">
            <div class="pt-4 pb-10 m-auto md:w-10/12 md:pb-4 lg:py-4">
              <h2 class="text-2xl italic font-extrabold md:mb-4 lg:text-3xl">
                ¡Descubre todo lo que podemos ofrecerte!
              </h2>
              <p class="font-semibold text-lg md:mb-2 lg:text-xl">
                Con los mejores profesionales que harán que tu evento sea un
                éxito.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="w-10/12 max-w-[1400px] m-auto mt-5">
        <h3 className="mb-8 text-center text-3xl font-extrabold">
          ¡Más de 30 años ayudándote a crecer!
        </h3>
        <ServicioList />
        <div className="mt-8 p-6 bg-ballBlue/[.20] rounded-3xl shadow-md">
          <h2 className="text-xl text-center font-extrabold mb-4 md:text-2xl">
            PODEMOS OFRECERTE UN AMPLIO SERVICIO DE CÁTERING
          </h2>
          <div className="flex flex-col justify-center font-bold text-center sm:flex-row sm:text-left">
            <ul className="list-disc list-inside">
              <li>Healthy coffee break</li>
              <li>Brunch</li>
              <li>Menú Business</li>
            </ul>
            <ul className="list-disc list-inside  sm:pl-12 md:pl-24">
              <li>Menú Vegetariano</li>
              <li>Menú Celíacos</li>
              <li>Catering Networking</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="w-10/12 max-w-[1400px] m-auto mt-5">
        <h3 className="mt-10 text-center text-3xl font-extrabold">
          DESCUBRE NUESTRAS SALAS
        </h3>

        <section class="max-w-[1400px] m-auto mt-6">
          {/* <div className="grid grid-cols-2 gap-4">
            <p className="text-center font-semibold items-center bg-deepSaffron/[.9] py-8 rounded-3xl shadow-md">
              SALÓN VILAFORTUNY
            </p>
            <p className="text-center font-semibold items-center bg-deepSaffron/[.9] py-8 rounded-3xl shadow-md">
              SALÓN TONI
            </p>
          </div> */}
          <aside class="salas-izquierda">
            <div>
              <h5>Salón Vilafortuny</h5>
              <ul>
                <li>
                  Amplia sala con vistas a Vilafortuny situada en la primera
                  planta
                  <ul>
                    <li className="list-disc list-inside">
                      Disponible acceso en ascensor
                    </li>
                    <li className="list-disc list-inside">
                      Salida directa a la terraza de 25m² desde la sala
                    </li>
                  </ul>
                </li>
                <li>Capacidad para hasta 100 personas</li>
                <li>Pantalla gigante y equipo de audio</li>
              </ul>
            </div>
            <img src={Vilafortuny} alt="Piscina" class="" />
          </aside>
          <aside class="salas-derecha">
            <div>
              <h5>Salón Toni</h5>
              <ul>
                <li>
                  Gran espacio diáfano perfecto para formaciones o conferencias
                </li>
                <li>Acceso directo a la zona de piscina</li>
                <li>Capacidad para hasta 500 personas</li>
                <li>Pantalla gigante, equipo de audio y equipo de luces</li>
                <li>Posibilidad de montar un escenario</li>
              </ul>
            </div>
            <img src={Toni} alt="" class="w-96" />
          </aside>
          {/* <div className="grid grid-cols-2 gap-4 mt-4">
            <p className="text-center font-semibold items-center bg-deepSaffron/[.9] py-8 rounded-3xl shadow-md">
              HOTELET
            </p>
            <p className="text-center font-semibold items-center bg-deepSaffron/[.9] py-8 rounded-3xl shadow-md">
              OASIS
            </p>
          </div> */}
          <aside class="salas-izquierda">
            <div>
              <h5>Hotelet</h5>
              <ul>
                <li>
                  Disponemos de 54 habitaciones para que disfrutes de una noche
                  de descanso después de tus eventos
                </li>
              </ul>
            </div>
            <img src={Habitacion} alt="" />
          </aside>
          <aside class="salas-derecha">
            <div>
              <h5>Oasis</h5>
              <ul>
                <li>Gran espacio de terraza con piscina</li>
                <li>Escenario, pantalla gigante, equipo de audio y de luces</li>
                <li>Zona VIP con sofás disponible</li>
                <li>Múltiples barras</li>
                <li>Entrada privada</li>
              </ul>
            </div>
            <img src={Oasis} alt="" />
          </aside>
        </section>
      </section>
      <Footer></Footer>
      <FooterBar></FooterBar>
    </div>
  );
};

export default ForBusinessPage;
