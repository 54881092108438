import React from "react";
import { Link } from "react-router-dom";
import facebook from "../assets/icons/facebook.svg";
import instagram from "../assets/icons/instagram.svg";
import tripAdvisor from "../assets/icons/tripAdvisor.svg";

const Footer = () => {
  return (
    <div className="w-10/12 max-w-[1400px] m-auto pb-24 font-barlow lg:pb-16">
      <p className="w-11/12 mt-14 m-auto text-4xl font-black">
        Contacta con nosotros
      </p>
      <hr className="h-1 m-4 mx-auto bg-deepSaffron" />
      <div
        id="contacto"
        className="flex flex-col pb-8 bg-deepSaffron/30 rounded-[50px] md:pb-0 md:flex-row"
      >
        <iframe
          title="Google Maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3007.02716583834!2d1.0912906154166002!3d41.090253079292594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a14531d1c3b235%3A0x4b60cc820e96b8cc!2sHotelet%20el%20Retiro!5e0!3m2!1ses!2ses!4v1614945505422!5m2!1ses!2ses"
          style={{ border: 0 }}
          loading="lazy"
          className="w-11/12 mx-auto h-80 rounded-[50px] my-4 ml-4 md:w-[45%] md:h-auto"
        ></iframe>
        <div className="w-11/12 mx-auto md:w-[45%]">
          <div className="my-6 text-white">
            <h1 className="font-bold text-2xl mt-3">HOTELET ELRETIRO</h1>
            <p className="my-3">
              Esperamos que disfrutes mucho de tu estancia. Gracias por
              escogernos.
            </p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.es/maps/place/Hotelet+elRetiro/@41.0902373,1.0909639,17z/data=!3m1!4b1!4m9!3m8!1s0x12a14531d1c3b235:0x4b60cc820e96b8cc!5m2!4m1!1i2!8m2!3d41.0902333!4d1.0935388!16s%2Fg%2F1vttzs0s?entry=ttu"
            >
              A7 KM 1148 Hotelet elRetiro
            </a>
            <br />
            <a href="tel:977364400">+34 977-364-400</a>
            <br />
            <a href="mailto:recepcion@hotelet.com">recepcion@hotelet.com</a>
            <br />
            <Link to="/bedrooms" className="text-white">
              Habitaciones
            </Link>
          </div>
          <div className="w-36 flex justify-between mb-6">
            <img
              src={facebook}
              className="w-10 h-10 rounded-full p-2 bg-white"
              alt="Facebook"
            />
            <img
              src={instagram}
              className="w-10 h-10 rounded-full p-2 bg-white"
              alt="Instagram"
            />
            <img
              src={tripAdvisor}
              className="w-10 h-10 rounded-full p-2 bg-white"
              alt="Trip Advisor"
            />
          </div>
          <iframe
            title="HS Form"
            src="https://share.hsforms.com/118l6suFkSwOTNF-nBBlQ-g5m2dx"
            id="hs-form-iframe-1"
            scrolling="no"
            className="w-full max-w-[800px] h-[850px] sm:h-[790px] md:h-[820px] lg:h-[760px] 2xl:h-[710px] md:pb-6"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Footer;
