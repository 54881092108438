import React, { useEffect } from "react";
import Header from "../components/Header";

import "../css/Bedrooms.css";

const BedroomsPage = () => {
  useEffect(() => {
    window.optionsHG = {
      pcode: "421",
      typo: "apartments",
      lang: "es",
      index: 1234,
      min_occupancy: 1,
      max_occupancy: 4,
      num_room_col: 4,
      analitics: "UA-26990615-2",
    };

    window.iniHG && window.iniHG();
  }, []);

  return (
    <div className="font-barlow">
      <Header />
      {/* <h1>Habitaciones Disponibles</h1> */}
      {/* Aquí integrarás el motor de reservas */}
      <div
        id="hgContainer_1234"
        style={{ width: "100%", margin: "0 auto" }}
        className="container-fluid hgContainer mt-8"
      ></div>
      {/* <iframe
        title="motor"
        src="https://booking.hotelgest.com/v4/calendar.html?pcode=421"
        height="1400"
        width="100%"
        allowtransparency="true"
      ></iframe> */}
    </div>
  );
};

export default BedroomsPage;
