import Header from "../components/Header";

const CateringsPage = () => {
  return (
    <div className="font-barlow">
      <Header />
      <h1>Banquetes</h1>
    </div>
  );
};

export default CateringsPage;
