import React from "react";
import { Link } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";
import FooterBar from "../components/FooterBar";

import Bussiness from "../assets/icons/bussiness.svg";
import CarCharger from "../assets/icons/carCharger.svg";
import Events from "../assets/icons/events.svg";
import GasStation from "../assets/icons/gasStation.svg";
import Open24h from "../assets/icons/open24h.svg";
import Parking from "../assets/icons/parking.svg";
import PetFriendly from "../assets/icons/petFriendly.svg";
import Phone from "../assets/icons/phone.svg";
import Restaurant from "../assets/icons/restaurant.svg";
import Swimmingpool from "../assets/icons/swimmingpool.svg";
import Wifi from "../assets/icons/wifi.svg";

import Banquetes from "../assets/banquetes.webp";
import Comedor from "../assets/comedor.webp";
import Duchas from "../assets/duchas.webp";
import Empresas from "../assets/empresas.webp";
import Fachada from "../assets/fachada.webp";
import Gastrobar from "../assets/gastrobar.webp";
import Habitacion from "../assets/habitacion.webp";
import Hotel from "../assets/hotel.webp";
import MenuDia from "../assets/menu-dia.webp";
import ParquingPrivado from "../assets/parquing-privado.webp";
import PetFriendlyy from "../assets/pet-friendly.webp";
import Piscina from "../assets/piscina.webp";

import "../css/Home.css";

const HomePage = () => {
  return (
    <div className=" bg-gray-100 font-barlow">
      <Header></Header>
      <section class="bg-ballBlue/60">
        <div class="flex flex-col md:flex-row">
          <img
            src={Hotel}
            alt="Fachada Hotelet elRetiro"
            class="w-full h-48 md:w-1/2 md:h-80 object-cover object-bottom 2xl:h-96"
          />
          <div class="w-11/12 h-auto m-auto md:w-1/2">
            <div class="pt-4 pb-24 m-auto sm:pb-20 md:w-10/12 md:pb-4 lg:py-4">
              <h2 class="text-xl italic font-extrabold md:mb-4 md:text-2xl lg:text-3xl">
                ¡Más de 30 años durmiendo contigo!
              </h2>
              <p class="font-semibold md:mb-2 md:text-lg lg:text-xl">
                30 años a vuestro servicio, mejorando todo cuanto esté en
                nuestra mano para hacer de cada estancia una experiencia que
                quieras repetir.
              </p>
              <strong class="italic font-extrabold md:text-lg xl:text-xl">
                ¡No somos un hotel grande, pero somos un gran hotel!
              </strong>
            </div>
          </div>
        </div>
      </section>

      <div class="absolute flex flex-col left-1/2 -translate-y-1/2 -translate-x-1/2 bg-deepSaffron rounded-2xl md:flex-row">
        <div class="m-auto py-4">
          {/* <input
            onclick='window.open(`https://booking.hotelgest.com/v4/?pcode=421&dfrom="+document.getElementById("dfromDate").value+"&dto="+document.getElementById("dtoDate").value,"_blank`)'
            style={{ align: "center" }}
            class="bg-ballBlue rounded-lg max-h-[150px] my-3 mx-16 py-2 px-10 items-center text-xl font-bold text-white cursor-pointer"
            type="button"
            value="¡RESERVAR!"
          /> */}
          <a
            href="https://booking.hotelgest.com/v4/?pcode=421"
            target="_blank"
            rel="noopener noreferrer"
            class="bg-ballBlue rounded-lg max-h-[150px] mx-10 py-2 px-10 items-center text-xl font-bold text-white cursor-pointer"
          >
            ¡RESERVAR!
          </a>
        </div>
      </div>

      <div
        id="hgContainer_1234"
        style={{ width: "90%", margin: "0 auto" }}
        class="container-fluid hgContainer"
      ></div>

      <section class="bg-ballBlue/20 pt-28 pb-7 sm:pt-24 md:pt-16">
        <div class="max-w-[1450px] mx-auto flex flex-wrap justify-center">
          <div class="icons">
            <img src={Parking} alt="Parking gratuito" />
            <p>PARKING GRATUITO</p>
          </div>
          <div class="icons">
            <img src={Swimmingpool} alt="Piscina" />
            <p>PISCINA</p>
          </div>
          <div class="icons">
            <img src={Restaurant} alt="Restaurante 24h" />
            <p>RESTAURANTE</p>
          </div>
          <div class="icons">
            <img src={Phone} alt="Atención telefónica/whatsapp" />
            <p id="tel">ATENCIÓN TELEFÓNICA / WHATSAPP</p>
          </div>
          <div class="icons">
            <img src={CarCharger} alt="" />
            <p>PUNTO DE CARGA ELÉCTRICA</p>
          </div>
          <div class="icons">
            <img src={GasStation} alt="" />
            <p>ESTACIÓN DE SERVICIO</p>
          </div>
          <div class="icons">
            <img src={Wifi} alt="" />
            <p>WIFI GRATUITO</p>
          </div>
          <div class="icons">
            <img src={Events} alt="" />
            <p>BANQUETES</p>
          </div>
          <div class="icons">
            <img src={Bussiness} alt="" />
            <p>SERVICIO EMPRESAS</p>
          </div>
          <div class="icons">
            <img src={PetFriendly} alt="" />
            <p>PET FRIENDLY</p>
          </div>
          <div class="icons">
            <img src={Open24h} alt="" />
            <p>ABIERTO 24 HORAS</p>
          </div>
        </div>
      </section>

      <section class="w-10/12 max-w-[1400px] m-auto mt-6">
        <p class="w-11/12 m-auto text-4xl font-black">Nuestros espacios</p>
        <hr class="h-1 mx-auto bg-deepSaffron" />
        <aside class="espacios-izquierda">
          <div>
            <h5>Piscina</h5>
            <p>
              Disfruta de una amplia zona donde relajarse los días más calurosos
              del año
            </p>
          </div>
          <img src={Piscina} alt="Piscina" class="" />
        </aside>
        <aside class="espacios-derecha">
          <div>
            <h5>Restaurante elRetiro 24 horas</h5>
            <p>
              Contamos con una zona de restauración en la cual podrás probar
              diferentes platos como: hamburguesas, pizzas, burritos...
            </p>
            <strong class="text-xl font-bold italic">
              ¡TAMBIÉN DISPONEMOS DE MENÚ DIARIO!
            </strong>
          </div>
          <img src={Gastrobar} alt="" class="w-96" />
        </aside>
        <aside class="espacios-izquierda">
          <div>
            <h5>Terrazas</h5>
            <p>
              Disponemos de una terraza exterior amplia donde poder tomarte una
              caña fresquita y una terraza interior para que todos nuestros
              huéspedes disfruten al aire libre
            </p>
          </div>
          <img src={Fachada} alt="" />
        </aside>
        <aside class="espacios-derecha">
          <div>
            <h5>Salones</h5>
            <p>
              Contamos con varios salones que pueden albergar hasta 400 personas
              y están disponibles para su alquiler
            </p>
          </div>
          <img src={Comedor} alt="" />
        </aside>
      </section>

      <section class="w-10/12 max-w-[1400px] m-auto md:mt-6">
        <p class="w-11/12 m-auto text-3xl font-extrabold">Servicios</p>
        <hr class="w-[105%] h-1 mx-auto bg-ballBlue" />
        <aside class="servicios-derecha">
          <div>
            <h5>Empresas</h5>
            <p class="mb-3">
              Nos adaptamos a ti y a tus necesidades, disponemos de grandes y
              pequeños salones donde llevar a cabo distintas actividades
            </p>
            <div class="text-center">
              <Link
                to="/for-business"
                className="cursor-pointer bg-ballBlue px-6 py-2 font-bold rounded-xl lg:text-xl"
              >
                ¡DESCUBRE MÁS!
              </Link>
            </div>
          </div>
          <img src={Empresas} alt="" />
        </aside>
        <aside class="servicios-izquierda">
          <div>
            <h5>Banquetes y eventos</h5>
            <p class="mb-4">
              Celébrate! Disfruta de tu día más especial dejando todo el trabajo
              en nuestras manos
            </p>
            <div class="text-center">
              <a
                href="/"
                class="bg-ballBlue px-6 py-2 font-bold rounded-xl md:text-xl"
              >
                ¡DESCUBRE MÁS!
              </a>
            </div>
          </div>
          <img src={Banquetes} alt="" />
        </aside>
        <aside class="servicios-derecha">
          <div>
            <h5>¡Somos PET FRIENDLY!</h5>
            <p>
              Desde elRetiro entendemos que las mascotas son uno más en la
              familia. Por ello, son bienvenidas en nuestras instalaciones
            </p>
            <strong class="text-lg font-bold italic md:text-2xl">
              ¡Pregúntanos por las tarifas!
            </strong>
          </div>
          <img src={PetFriendlyy} alt="" />
        </aside>
        <aside class="servicios-izquierda">
          <div>
            <h5>Menú Diario</h5>
            <p>
              Disponemos de una gran variedad de platos que van variando según
              el día de la semana
            </p>
            <strong class="text-lg font-bold italic md:text-2xl">
              ¡Pregúntanos por tu estancia con media pensión!
            </strong>
          </div>
          <img src={MenuDia} alt="" />
        </aside>
        <aside class="servicios-derecha">
          <div>
            <h5>Estancias diurnas</h5>
            <p>
              Disponemos de habitaciones por horas, con parking privado y todas
              las comodidades a su alcance
            </p>
          </div>
          <img src={Habitacion} alt="" />
        </aside>
        <aside class="servicios-izquierda">
          <div>
            <h5>Parking privado con cargador</h5>
            <p>
              Contamos con un parking privado donde puedes encontrar un cargador
              para vehículos eléctricos
            </p>
          </div>
          <img src={ParquingPrivado} alt="" />
        </aside>
        <aside class="servicios-derecha">
          <div>
            <h5>Duchas</h5>
            <p>
              Recupera energías con nuestras duchas
              <br />
              Comodidad en la carretera
            </p>
          </div>
          <img src={Duchas} alt="" />
        </aside>
      </section>

      <section class="w-10/12 max-w-[1400px] mx-auto mt-6">
        <p class="w-11/12 m-auto font-black text-4xl mb-2">
          ¡No te pierdas ninguna oferta!
        </p>
        <hr class="h-1 mx-auto bg-deepSaffron" />
        <iframe
          title="newsletter"
          src="https://share.hsforms.com/1L55s61OxRS-w5rnx6HZIeg5m2dx"
          id="hs-form-iframe-1"
          style={{ marginTop: "0px" }}
          class="h-[500px] w-full max-w-[800px] mx-auto sm:h-[350px] md:h-[330px]"
          scrolling="no"
        ></iframe>
      </section>

      <section class="w-10/12 max-w-[1400px] m-auto mt-12 pb-12 mb-8 lg:h-[525px]">
        <p class="w-11/12 m-auto font-black text-4xl mb-2">
          La confianza de nuestros clientes
        </p>
        <hr class="w-[105%] h-1 mx-auto bg-ballBlue" />
        <div class="flex flex-col p-6 bg-ballBlue/20 rounded-[50px] mt-6 md:p-[45px] md:flex-row">
          <p class="w-full m-auto md:text-2xl md:w-1/2">
            <span class="text-amber-400 text-4xl">★★★★★</span>
            <br />
            Nuestra experiencia está avalada por cada uno de los clientes que ha
            pasado por nuestras instalaciones y ha disfrutado de nuestros
            servicios.
          </p>
          <iframe
            title="reviews"
            src="https://widgets.sociablekit.com/google-reviews/iframe/201493"
            frameborder="0"
            class="w-full md:w-1/2"
            height="400"
          ></iframe>
        </div>
      </section>
      {/* Otros elementos de tu página */}

      <Footer />
      <FooterBar />
    </div>
  );
};

export default HomePage;
